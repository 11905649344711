import './Footer.css'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter';
import  LinkedInIcon  from '@mui/icons-material/LinkedIn';
import banner1 from '../../assets/homepage/footer.jpg';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer style={{ backgroundImage:`url(${banner1})`}}>
            <div className="footer__container">
                <div className="footer__items__container">
                    <div className="footer__help__container">
                        
                        {/* <ul className='ul-footer-common-parent icons social_links'>
                            <a href="https://www.facebook.com/Rsolar.au" target="_blank">
                             <li className="social__link help__link fb combo">
                                
                                 <FacebookIcon /> 
                                
                            </li>
                            </a>
                            <a href="https://twitter.com/RSolar82290" target="_blank">
                            <li className="social__link help__link tw combo">
                              <TwitterIcon />
                            </li>
                            </a>
                            <a href="/" target="_blank">
                            <li className="social__link help__link ig combo">
                                 <InstagramIcon />
                             </li>
                            </a>
                            <a href="http://www.linkedin.com/in/r-solar-32597328b" target="_blank">
                            <li className="social__link help__link tw combo ">
                                 <LinkedInIcon />
                            </li>
                            </a>
                        </ul> */}
                        <ul className="fotter__help__links ul-footer-common-parent menu">
                            <li className="help__link   ">
                            <Link to="/about">ABOUT</Link>
                            </li>
                            <li className="help__link   ">
                            <Link to="/contact">CONTACT</Link>
                            </li>
                            
                        </ul>
                        <div class="footer-copyright">
                            <p>Copyright @ 2024 All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;