import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './TeslaSolar.css';
import TeslaPDPDesk from '../../assets/productpages/Sungrow_battery.jpg';
import TeslaPDPMob from '../../assets/productpages/Sungrow_battery.jpg';
import TeslaMobSwiper1 from '../../assets/productpages/Sungrow_battery.jpg';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import teslaPowerwall from '../../assets/productpages/Sungrow_battery.jpg';

const SungrowBattery = () => {
  return (
    <div className='product-page-super-parent'>
      <div className="custom-banner-section carousel">
        <div className="list">
          <div className="item" >
          {/* <video autoPlay loop muted controls className='product-main-banner'>
            <source src={teslabanner} type="video/mp4"/>
          </video> */}
          <div className='DesktopBannerTesla'>
            <img src={TeslaPDPDesk} alt='DesktopBanner' width={1920} height={1080}/>
          </div>
          <div className='MobileBannerTesla'>
            <img src={TeslaPDPMob} alt='MobileBanner' width={720} height={1280}/>
          </div>
            <div className="content MobBannerContent">
              <div className="title montserrat-bold">Sungrow Batteries</div>
              <div className="des montserrat-regular">Sungrow Batteries are cutting-edge energy storage solutions designed to enhance the efficiency and reliability of solar power systems. Manufactured by Sungrow, a global leader in renewable energy technology, these batteries store excess solar energy for use during periods of low sunlight or high energy demand. They are known for their high energy density, long lifespan, and robust performance. </div>
              <div class="buttons">
                <Link to='/get-quote'>
                  <button className='lato-bold'>Get Started</button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-brand'>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-e4817c9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e4817c9" data-element_type="section">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b45e63c" data-id="b45e63c" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-9154ea2 elementor-widget elementor-widget-image" data-id="9154ea2" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container DeskTopTesla">
                    <img src={TeslaMobSwiper1} />
                  </div>
                  <div class="elementor-widget-container MobileTesla">
                    <img src={teslaPowerwall} />
                  </div>
                </div>
              </div>
              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4558e86" data-id="4558e86" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6b5e14b elementor-widget elementor-widget-heading" data-id="6b5e14b" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Reliable Energy Storage for a Sustainable Future</h2>		</div>
                  </div>
                  <div class="elementor-element elementor-element-3e6203e elementor-widget elementor-widget-text-editor" data-id="3e6203e" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>Sungrow Solar Batteries represent the pinnacle of energy storage technology, providing a reliable and efficient solution to harness and store solar energy. Developed by Sungrow, a renowned leader in renewable energy solutions, these batteries are designed to capture excess solar energy during peak sunlight hours and store it for use during times of low solar production or high energy demand.</p>						
                      <p> </p>
                      <p>Sungrow Solar Batteries deliver high energy density, robust performance, and advanced safety features, making them an ideal solution for efficient energy storage. Perfect for residential, commercial, and industrial applications, these batteries ensure energy independence, lower electricity costs, and support sustainable energy practices. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className='product-specification'>
        <h2>Sungrow Specification</h2>
        <div className='specification-parent'>
          <div className='specification-child'>
            <h3>Power</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Rated voltage (V)</h5>
                <p>192</p>
              </div>
              <div className='spec-card'>
                <h5>Max. voltage (V)</h5>
                <p>219</p>
              </div>
              <div className='spec-card'>
                <h5>Ambient temperature</h5>
                <p>-20-50 °C</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Features</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Suitable for emergency power</h5>
                <p>Yes</p>
              </div>
              <div className='spec-card'>
                <h5>Usable battery capacity</h5>
                <p>9.6 kWh</p>
              </div>
              <div className='spec-card'>
                <h5>Max. depth of discharge</h5>
                <p>100 %</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Warranty</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Duration</h5>
                <p>10 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SungrowBattery